import Alpine from 'alpinejs';
import htmx from 'htmx.org';
import focus from '@alpinejs/focus';
import masonry from 'alpinejs-masonry';


Alpine.plugin(focus);
Alpine.plugin(masonry);

window.Alpine = Alpine;
window.htmx = htmx;


if (window.htmx) {
    import('htmx-ext-head-support').then(() => {
        console.log('htmx-ext-head-support loaded');
    });
}

if(htmxNonce !== undefined) {
    document.addEventListener('htmx:configRequest', function(event) {
        event.detail.headers['X-WP-Nonce'] = htmxNonce;
    });
}

document.addEventListener('DOMContentLoaded', function() {
    Alpine.start();
});